import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const NotFound = () => {
  return (
    <div align="center">
      <h1 style={{ color: "white" }}> 404 - Sidan hittas inte</h1>
      <br></br>
      <Button>
        <Link to="/" style={{ color: "white" }}>
          Tillbaka
        </Link>
      </Button>
    </div>
  );
};

export default NotFound;
