import React, { useState } from "react";
import queen from "../../queens";
import { Row, Col } from "react-bootstrap";
import Product from "../Product";
import konungalength from "../images/konungalength.jpg";

function Allt() {
  function addDefaultSrc(ev) {
    ev.target.src = konungalength;
  }
}

function Q1() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2>Sveriges Kungar {queen.length}</h2>
        {queen &&
          queen.map((queen) => (
            <Col key={queen._id} value={queen.name} sm={4} lg={4} md={4} xl={4}>
              <Product queen={queen} />
              <br></br>
            </Col>
          ))}
      </Row>
    </>
  );
}

function Q2() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Bernadotteska Ätten</h2>
        {queen &&
          queen
            .filter((queen) => queen.rating === "Bernadotte")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q3() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Vasaätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Vasa")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q4() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Munsö</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Munsö")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q5() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Stenkilska Ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Stenkilska")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q6() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Erikska Ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Erikska")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q7() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Sverkerska Ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Sverkerska")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q8() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Folkunga Ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Folkunga")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q9() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Pfalziska ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Pfalziska")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q10() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Oldenburg ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Oldenburg")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q11() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Mecklenburg Ätten</h2>
        {queen &&
          queen
            .filter((queen) => queen.rating === "Mecklenburg")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q12() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Bonde</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Bonde")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q13() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Hessiska ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Hessiska")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q14() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Holstein-Gottorp Ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Holstein-Gottorp")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q15() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Kalmar Unionen</h2>
        {queen &&
          queen
            .filter((queen) => queen.rating === "Kalmar")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q16() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Estridsen</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Estridsen")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q17() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Grip</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Grip")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q18() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Pfalz-Neumarkt</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Pfalz-Neumarkt")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q19() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Oxenstierna</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Oxenstierna")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q20() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Övrigt</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "other")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q21() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Natt och Dag</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "nattochdag")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q22() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Trolle</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "trolle")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

function Q23() {
  <Allt />;

  return (
    <>
      <Row id="main">
        <h2 style={{ color: "white" }}>Tott ätten</h2>
        {queen &&
          queen

            .filter((queen) => queen.rating === "Tott")
            .map((queen) => (
              <Col
                key={queen._id}
                value={queen.name}
                sm={4}
                lg={4}
                md={4}
                xl={4}
              >
                <Product queen={queen} />
                <br></br>
              </Col>
            ))}
      </Row>
    </>
  );
}

export {
  Q1,
  Q2,
  Q3,
  Q4,
  Q5,
  Q6,
  Q7,
  Q8,
  Q9,
  Q10,
  Q11,
  Q12,
  Q13,
  Q14,
  Q15,
  Q16,
  Q17,
  Q18,
  Q19,
  Q20,
  Q21,
  Q22,
  Q23,
};
