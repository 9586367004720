import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaRegEnvelope } from "react-icons/fa";

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <Row style={{ bg: "primary" }}>
      <hr style={{ color: "white" }}></hr>
      <Col>
        <footer>
          <Container>
            <Row>
              <Col className="text-center py-3">
                <a href="mailto:tobias@supply24.se">
                  {" "}
                  <FaRegEnvelope />
                </a>
              </Col>
            </Row>
            <Col align="center">
              {" "}
              kungarna.se &nbsp; Copyright &nbsp;{currentYear}{" "}
            </Col>
          </Container>
        </footer>
      </Col>
    </Row>
  );
};

export default Footer;
