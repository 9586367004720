import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/bootstrap.custom.css";
import "./assets/styles/index.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import QueenScreen from "./screens/QueenScreen";
import QueenIdScreen from "./screens/QueenIdScreen";
import Home from "./screens/Home";
import Queening1 from "./components/Queening1";
import NotFound from "./components/NotFound";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<Home />} />
      <Route index={true} path="/" element={<Home />} />

      <Route index={true} path="/queenking" element={<QueenScreen />} />
      <Route index={true} path="/queening1" element={<Queening1 />} />
      <Route index={true} path="/queenid/:id" element={<QueenIdScreen />} />
      <Route index={true} path="/*" element={<NotFound />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
