import React from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PiCross } from "react-icons/pi";
import { FaCrown } from "react-icons/fa";
import konungalength from "../components/images/konungalength.jpg";

function addDefaultSrc(ev) {
  ev.target.src = konungalength;
}

const Product = ({ queen }) => {
  return (
    <Row>
      <Col>
        <Card bg="black" border="white">
          <Link to={`/queenid/${queen._id}`} style={{ textDecoration: "none" }}>
            {" "}
            <Row>
              <Row>
                {" "}
                <Col align="left"> &nbsp;{queen._id} </Col>{" "}
              </Row>
              <Col align="center">
                {" "}
                <br></br>
                <Card.Title style={{ color: "white" }}>
                  {" "}
                  {queen.name}{" "}
                </Card.Title>{" "}
                <Row>
                  <Col align="center" style={{ color: "white", marginTop: 5 }}>
                    <small>
                      {" "}
                      {queen.lifetime} <PiCross />
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col align="center">
                    {" "}
                    <Image
                      src={queen.image}
                      height={100}
                      width={100}
                      onError={addDefaultSrc}
                    ></Image>
                  </Col>
                </Row>
                <Row>
                  <Col align="center" style={{ color: "white" }}>
                    {" "}
                    <small>
                      {" "}
                      <i>
                        {" "}
                        <FaCrown />
                      </i>{" "}
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col align="center" style={{ color: "white" }}>
                    {" "}
                    <small> {queen.in_power} </small>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br></br>
          </Link>
        </Card>{" "}
      </Col>
    </Row>
  );
};

export default Product;
