const queen = [
  {
    _id: "1",
    image: "/images/970.jpg",
    name: "Erik Segersäll",
    lifetime: "c. 945 A.D. - 995 A.D.",
    in_power: "970 A.D. - 995 A.D.",
    general: "Sveriges första Kung",
    rating: "Munsö",
    numReviews: "5",
    other: "",
  },

  {
    _id: "2",
    image: "/images/995.jpg",
    name: "Olof Skötkonung",
    lifetime: "c. 980 A.D. - 1022 A.D.",
    in_power: "995 A.D. - 1022 A.D.",
    general: "Erik Segersäll ätt",
    rating: "Munsö",
    numReviews: "5",
    other: "",
  },

  {
    _id: "3",
    image: "/images/1022.jpg",
    name: "Anund Jakob",
    lifetime: "c. 1008 A.D. - 1050 A.D.",
    in_power: "1022 A.D. - 1050 A.D.",
    general: "Son till Olof Skötkonung och Estrid av Obotriterna. Dog i sotdöd",
    rating: "Munsö",
    numReviews: "5",
    other: "Känd under namnet Emund Kolbränna",
  },

  {
    _id: "4",
    image: "/images/1050.jpg",
    name: "Emund den Gamle",
    lifetime: "c. 1000 A.D. - 1060 A.D.",
    in_power: "1050 A.D. - 1060 A.D.",
    general:
      "Son till Olof Skötkonung och frillan Edla av Venden. Dog i sotdöd",
    rating: "Munsö",
    numReviews: "5",
    other: "Känd under namnet Emund Slemme",
  },
  {
    _id: "5",
    image: "/images/1060.jpg",
    name: "Stenkil Ragnvaldsson",
    lifetime: "c. 1030 A.D. -  1066 A.D.",
    in_power: "1060 A.D.-  1066 A.D.",
    general: "Son till Ragnvald den gamle och möjligen till Astrid Nialsdotter",
    rating: "Stenkilska",
    numReviews: "5",
    other: "Efter Stenkils död utbryter strider om kungamakten",
  },
  {
    _id: "6",
    image: "/images/1066.jpg",
    name: "Erik & Erik",
    lifetime: " -  -  ",
    in_power: "1066 A.D. - 1067 A.D.",
    general:
      "Denna tid bestod av strider om makten. Gissningar säger att deras kompletta namn skulle vara Erik Hedningen och Erik Stenkilsson. Bilden visar Drottning Kristinas silvertron",
    rating: "Stenkilska",
    numReviews: "5",
    other: "Stupade i strid med Erik",
  },
  {
    _id: "7",
    image: "/images/1067.jpg",
    name: "Halsten Stenkilsson",
    lifetime: "c. 1049 A.D.- c. 1080 A.D.",
    in_power: "c. 1067 A.D. -  1070 A.D.",
    general:
      "Kung i Svealand, son till Stenkil och möjligen till Emund den gamles dotter. Halsten är omgiven i sveriges älsta landskapslag, nämligen den äldre Västgötalagen",
    rating: "Stenkilska",
    numReviews: "5",
    other: "Avsatt och fördriven",
  },

  {
    _id: "8",
    image: "/images/1070.png",
    name: "Anund Gårdske",
    lifetime: "- A.D.- c. 1075 A.D.",
    in_power: "1070 A.D. -  1075 A.D.",
    general:
      "Kung i Svealand, Son till Stenkil och Emund den gamles dotter. Gårske kom troligen från det gamla Kievriket i nuvarande Ryssland resp. Ukraina.",
    rating: "Stenkilska",
    numReviews: "5",
    other: "Avsatt och fördriven",
  },

  {
    _id: "9",
    image: "/images/rode.jpeg",
    name: "Håkan Röde",
    lifetime: "- A.D. -  c. 1079 A.D.",
    in_power: "1070 A.D. - c. 1079 A.D.",
    general: "Osäker period vad gäller makt och årtal",
    rating: "Stenkilska",
    numReviews: "5",
    other: "",
  },

  {
    _id: "10",
    image: "/images/1080.jpeg",
    name: "Inge den Äldre",
    lifetime: "- A.D. - c. 1105 A.D.",
    in_power: "c. 1080 A.D. - 1100 A.D.",
    general:
      "Son till Stenkil och Emund den gamles dotter. Enligt en isländsk uppgift skall han vid mitten av 1080-talet fördrivits från Mälardalen, men återvänt efter cirka tre år. Sedan kung till sin död.",
    rating: "Stenkilska",
    numReviews: "5",
    other: "",
  },

  {
    _id: "11",
    image: "/images/blot.jpg",
    name: "Blot-Sven",
    lifetime: "c. 1080 A.D. - c. 1083 A.D.",
    in_power: "c. 1080 A.D. - c. 1083 A.D.",
    general:
      "Kung i Svealand vid mitten av 1080-talet vid Inge den äldres fördrivning. Han kan ha varit en sagokung",
    rating: "Stenkilska",
    numReviews: "5",
    other: "",
  },
  {
    _id: "12",
    image: "/images/1105.jpg",
    name: "Filip Halstensson",
    lifetime: "c. 1030 A.D. -  1118 A.D.",
    in_power: "c. 1105 A.D.-  1118 A.D.",
    general:
      "Son till Halsten och känd som Filippus. Kung Filip begravdes troligen med sin bror (Inge den yngre) i Vreta Kloster, Östergötland",
    rating: "Stenkilska",
    numReviews: "5",
    other: "",
  },
  {
    _id: "13",
    image: "/images/inge2.jpg",
    name: "Inge den yngre",
    lifetime: " c. 1100 A.D. - c. 1125 A.D",
    in_power: "c. 1110 A.D. - 1125 A.D",
    general:
      "Son till Halsten. Vid Inge den yngres död utbryter på nytt tronstrider, och källorna är motsägelsefulla 1125–1130.",
    rating: "Stenkilska",
    numReviews: "5",
    other: "",
  },
  {
    _id: "14",
    image: "/images/1125a.jpg",
    name: "Ragnvald Knaphövde",
    lifetime: "- A.D.- c. 1130 A.D.",
    in_power: "c. 1125 A.D. - c. 1130 A.D.",
    general:
      "Troligen även kallad Ragnvald Ingesson. Vald till kung i Svealand vid Inge den yngres död. Efternamnet kan komma av att han hade ett klotformat huvud.",
    rating: "Stenkilska",
    numReviews: "5",
    other: "Dödad efter kort tid på tronen",
  },
  {
    _id: "15",
    image: "/images/1105.jpg",
    name: "Magnus Nilsson (Magnus den starke)",
    lifetime: "1106 A.D. - 1134 A.D.",
    in_power: "1125 A.D. - 1130 A.D.",
    general:
      "Kan ha varit vald till kung i Västergötland vid Inge den yngres död. Avsatt och fördriven från Sverige. känd under namnet Magnus den starke. Begravd i Vreta Kloster",
    rating: "Stenkilska",
    numReviews: "5",
    other:
      "Avsatt och fördriven från Sverige. =>=> Stupad i slaget vid Fotevik i Skåne",
  },

  {
    _id: "16",
    image: "/images/1130.jpg",
    name: "Sverker den äldre",
    lifetime: "- A.D. - 1056 A.D",
    in_power: "1130 A.D. - 1156 A.D",
    general: "Möjligen mördad på väg till julottan",
    rating: "Sverkerska",
    numReviews: "5",
    other: "",
  },

  {
    _id: "17",
    image: "/images/1156.png",
    name: "Erik den helige",
    lifetime: " c. 1120 A.D. - 1060 A.D",
    in_power: "c. 1156 A.D. - 1160 A.D",
    general:
      "Enligt en sentida legend, mördad av Magnus Henriksson. Han startade den Erikska ätten",
    rating: "Erikska",
    numReviews: "5",
    other: "",
  },
  {
    _id: "18",
    image: "/images/1160.jpg",
    name: "Magnus Henriksson",
    lifetime: "1130 A.D. - 1161 A.D.",
    in_power: "1160 A.D.-  1161 A.D.",
    general:
      "Född i Danmark och av dansk ätt. Son till Henrik Skadelår och Inge den äldres barnbarn Ingrid. Bilden, som är en avbildning från 1600 talet då orginalet brann upp, visar när Magnus dödade Erik.",
    rating: "Estridsen",
    numReviews: "5",
    other: "Dödad av Karl Sverkersson vid Örebro",
  },

  {
    _id: "19",
    image: "/images/1161.jpg",
    name: "Karl Sverkersson",
    lifetime: " - A.D. -  1167 A.D.",
    in_power: "c. 1161 A.D. - 1167 A.D.",
    general: "Son till Sverker den äldre och Ulvhild Håkansdotter",
    rating: "Sverkerska",
    numReviews: "5",
    other: "Mördad 12 april 1167 på Visingsö av Knut Eriksson",
  },

  {
    _id: "20",
    image: "/images/kol.jpg",
    name: "Kol Sverkersson",
    lifetime: "? A.D.- c. 1070 A.D.",
    in_power: "1167 A.D. - 1070 A.D.",
    general:
      "Sägs vara en oäkta son till Karl Sverkersson. Samregent i en del av Sverige med brodern Burislev",
    rating: "Sverkerska",
    numReviews: "5",
    other:
      "1173 stupade han vid Bjälbo i Östergötland i strid med Knut Eriksson",
  },
  {
    _id: "21",
    image: "/images/burislev.jpg",
    name: "Burislev Sverkersson",
    lifetime: "- A.D. - c. 1173 A.D.",
    in_power: "1167 A.D. - 1173 A.D.",
    general:
      "Kung i Östergötland. Fick sitt namn från sin fars andra giftemål med en polska. ",
    rating: "Sverkerska",
    numReviews: "5",
    other: "1173 stupad i strid med Knut Eriksson",
  },

  {
    _id: "22",
    image: "/images/1167.png",
    name: "Knut Eriksson",
    lifetime: "c. 1150 A.D. - c. 1196 A.D.",
    in_power: "1167 A.D. - c. 1195/1196 A.D.",
    general:
      "Son till Erik den helige och Kristina Björnsdotter. Kung även i Östergötland 1173 vid Kol Sverkerssons död",
    rating: "Erikska",
    numReviews: "5",
    other: "Död 1195 eller 1196 på Eriksbergs kungsgård i Västergötland",
  },

  {
    _id: "23",
    image: "/images/1195.jpg",
    name: "Sverker den yngre ",
    lifetime: "1164 A.D. - 1210 A.D.",
    in_power: "1195/1196 A.D. - 1208 A.D.",
    general:
      "Son till Karl Sverkersson. Avsatt efter slaget vid Lena. Dödad vid slaget vid Gestilren som blev slutet för maktkampen mellan den sverkerska och erikska ätten.",
    rating: "Sverkerska",
    numReviews: "https://sv.wikipedia.org/wiki/Sverker_den_yngre",
    other: "Stupad 17.e eller 18.e juli 1210 i slaget vid Gestilren",
  },
  {
    _id: "24",
    image: "/images/1208.gif",
    name: "Erik Knutsson",
    lifetime: "1180 A.D. -  1216 A.D.",
    in_power: "1208 A.D.-  1216 A.D.",
    general: "Son till Knut Eriksson",
    rating: "Erikska",
    numReviews: "5",
    other: " Död hetsig feber eller tvinsot på Visingsö",
  },
  {
    _id: "25",
    image: "/images/1216.jpg",
    name: "Johan Sverkersson",
    lifetime: " 1201 A.D. -  1222 A.D.",
    in_power: "1216 A.D. - 1222 A.D.",
    general: "Son till Sverker den yngre Karlsson och Ingegärd Birgersdotter",
    rating: "Sverkerska",
    numReviews: "5",
    other: "Död 10 mars 1222 i sjukdom på Visingsö",
  },
  {
    _id: "26",
    image: "/images/laspeohalte.jpg",
    name: "Erik Eriksson (läspe och halte)",
    lifetime: "1216 A.D.- - 1250 A.D.",
    in_power: "1222 A.D. - 1229 A.D.",
    general:
      "Son till Erik Knutsson och Rikissa av Danmark. Avsatt efter slaget vid Olustra",
    rating: "Erikska",
    numReviews: "5",
    other: "Död på okänd plats",
  },

  {
    _id: "27",
    image: "/images/1229.jpg",
    name: "Knut Långe Holmgersson",
    lifetime: "c. 1200 A.D. - 1234 A.D",
    in_power: "1229 A.D. - 1234 A.D",
    general:
      "Son till Holmger och eventuellt släkt med den Erikska ätten. Tillhörde troligen polistk Folkungarna. Man spekulerar om hans härstamning. Det är troligt att flera lagar reviderades under dennes tid i samklang med revisioner i övriga Europa.",
    rating: "Sverkerska",
    numReviews: "https://sv.wikipedia.org/wiki/Knut_L%C3%A5nge",
    other: "",
  },

  {
    _id: "28",
    image: "/images/laspeohalte.jpg",
    name: "Erik Eriksson (läspe och halte)",
    lifetime: "1216 A.D. - 1250 A.D.",
    in_power: "1234 A.D. - 1250 A.D.",
    general: "Återinsatt i samband med Knut Långes död",
    rating: "Erikska",
    numReviews: "5",
    other: "",
  },

  {
    _id: "29",
    image: "/images/jarl.jpg",
    name: "Birger Jarl",
    lifetime: "c. 1200 A.D. - 1266 A.D.",
    in_power: "1248 A.D. - 1266 A.D.",
    general: "Birger Jarl var regerande riksjarl i mars 1248",
    rating: "Folkunga",
    numReviews: "5",
    other: "Död 21 oktober 1266 på Jälbolung i Västergötland",
  },
  {
    _id: "30",
    image: "/images/1250.jpg",
    name: "Valdemar Birgersson",
    lifetime: "c. 1238/1239 A.D. -  1302 A.D.",
    in_power: "c. 1250 A.D.-  1275 A.D.",
    general:
      "Son till Birger Jarl och kung Erik Erikssons syster Ingeborg. Övertog styrelsen vid Birger Jarls död 1266. Avsatt av Magnus Ladulås",
    rating: "Folkunga",
    numReviews: "5",
    other: "Dog som fånge i Nyköpingshus 1302",
  },
  {
    _id: "31",
    image: "/images/1275.jpg",
    name: "Magnus Ladulås",
    lifetime: "  1240 A.D. -  1290 A.D.",
    in_power: "1275 A.D. - 1290 A.D.",
    general: "Son till Birger Jarl och Ingeborg Eriksdotter",
    rating: "Folkunga",
    numReviews: "5",
    other: "Dog 18 december, 1290 på Näs slott, Visingsö",
  },
  {
    _id: "32",
    image: "/images/1290.jpg",
    name: "Birger Magnusson ",
    lifetime: "1080 A.D.- - 1321 A.D.",
    in_power: "1290 A.D. - 1318 A.D.",
    general: "Son till Magnus Ladulås och Helvig av Holstein",
    rating: "Folkunga",
    numReviews: "5",
    other: "Död 31 maj 1321 i Danmark",
  },

  {
    _id: "33",
    image: "/images/1318.png",
    name: "Mats Kettilmundsson",
    lifetime: "c. 1300 A.D. - 1326 A.D.",
    in_power: "1318 A.D. - 1319 A.D.",
    general: "Rikshövitsman. Avsatte Birger Magnusson.",
    rating: "other",
    numReviews: "5",
    other: "",
  },

  {
    _id: "34",
    image: "/images/1319.jpg",
    name: "Magnus Eriksson",
    lifetime: "1316 A.D. - 1374 A.D.",
    in_power: "1319 A.D. - 1364 A.D.",
    general:
      "Son till hertig Erik Magnusson och Ingeborg Håkansdotter. Avsatt Februari 1364 avsatt vid Albrekts av Mecklenburg kungaval",
    rating: "Folkunga",
    numReviews: "5",
    other: "Död  1 december 1374 drunknad vid skeppsbrott vid Bergen i Norge",
  },

  {
    _id: "35",
    image: "/images/1356.jpg",
    name: "Erik Magnusson",
    lifetime: "1339 A.D. - 1359 A.D.",
    in_power: "1356 A.D. - 1359 A.D.",
    general:
      " Han var son till Magnus Eriksson och Blanka av Namur. Utropad till kung i opposition mot Magnus Eriksson 17 oktober 1356. Erkänd Kung i stora delar av landet.",
    rating: "Folkunga",
    numReviews: "5",
    other: "Dog i pest",
  },
  {
    _id: "36",
    image: "/images/1362.jpg",
    name: "Håkan Magnusson",
    lifetime: "1340 A.D. -  1380 A.D.",
    in_power: "1362 A.D.-  1364 A.D.",
    general:
      "Son till Magnus Eriksson och Blanka av Namur. Avsatt Februari 1364 vid Albrekts av Mecklenburg kungaval ",
    rating: "Folkunga",
    numReviews: "https://sv.wikipedia.org/wiki/H%C3%A5kan_Magnusson",
    other: "Död 1380 i Oslo i Norge",
  },
  {
    _id: "37",
    image: "/images/1364.jpg",
    name: "Albrekt av Mecklenburg",
    lifetime: "c. 1338 A.D. -  1412 A.D.",
    in_power: "1363 A.D. - 1389 A.D.",
    general:
      "Son till hertig Albrekt den store av Mecklenburg och Magnus Erikssons syster Eufemia Eriksdotter. 24 februari 1389 besegrad i slaget vid Åsle och avsatt av Margareta",
    rating: "Mecklenburg",
    numReviews: "5",
    other: "Dog i Mecklenburg",
  },
  {
    _id: "38",
    image: "/images/1389.jpg",
    name: "Drottning Margareta",
    lifetime: "1353 A.D.- - 1412 A.D.",
    in_power: "1389 A.D. - 1412 A.D.",
    general:
      "Dotter till kung Valdemar Atterdag av Danmark och Helvig av Slesvig. Samregent med Erik av Pommern",
    rating: "Kalmar",
    numReviews:
      "https://commons.wikimedia.org/wiki/File:Margaret_of_Denmark,_Norway_%26_Sweden_(1389)_effigy_2010_(2).jpg",
    other: "Dog 1412 i pestdöd i Flensburg",
  },
  {
    _id: "39",
    image: "/images/1396.jpg",
    name: "Erik av Pommern",
    lifetime: "1382 A.D. - 1459 A.D.",
    in_power: "1396 A.D. - 1435 A.D.",
    general:
      "Samregent med Drottning Margareta av Danmark. Ensam regent från 1412",
    rating: "Grip",
    numReviews: "5",
    other: "Son till Hertig Vratislav VII av Pommern och Maria av Mecklenburg",
  },

  {
    _id: "142",
    image: "/images/1435.png",
    name: "Engelbrekt Engelbrektsson",
    lifetime: " - A.D. - c. 1436 A.D.",
    in_power: "1435 A.D. - c. 1436  A.D.",
    general: "",
    rating: "Engelbrektsätten",
    numReviews: "https://sv.wikipedia.org/wiki/Engelbrekt_Engelbrektsson",
    other: "",
  },

  {
    _id: "40",
    image: "/images/1448.jpg",
    name: "Karl Knutsson",
    lifetime: "1408/1409 A.D. - 1470 A.D.",
    in_power: "1438 A.D. - 1440 A.D.",
    general:
      "Son till riddar Knut Tordsson (Bonde) och Margareta Karlsdotter (Sparre av Tofta)",
    rating: "Bonde",
    numReviews: "5",
    other: "",
  },

  {
    _id: "41",
    image: "/images/1440.png",
    name: "Kristofer av Bayern",
    lifetime: "1416 A.D. - 1448 A.D.",
    in_power: "1440 A.D.-  1448 A.D.",
    general:
      "Son till hertig Johan av Pfalz och Erik av Pommerns syster Katarina av Pommern. Vald vilkorligt till Kung 1440 och hyllad som kung ett år efter.",
    rating: "Pfalz-Neumarkt",
    numReviews: "5",
    other: "Dog i Helsingborg",
  },

  {
    _id: "42",
    image: "/images/oxen.jpeg",
    name: "Bengt Jönsson (Oxenstierna)",
    lifetime: " c. 1395 A.D. - c. 1450 A.D.",
    in_power: "1448 A.D. - c. 1450  A.D.",
    general:
      "Riksföreståndare. Son till Jöns Bengtsson (Oxenstierna) d.ä. och Märta Finvidsdotter (Frössviksätten) samregerade med sin bror Nils Jönsson",
    rating: "Oxenstierna",
    numReviews: "5",
    other: "",
  },

  {
    _id: "43",
    image: "/images/djursholm.jpg",
    name: "Nils Jönsson (Oxenstierna)",
    lifetime: "c. 1394 A.D. - c. 1450 A.D.",
    in_power: "1448 A.D. -  1450 A.D.",
    general:
      "Han köpte en holme utanför Djursholm och senare grundade han Djursholm",
    rating: "Oxenstierna",
    numReviews: "https://sv.wikipedia.org/wiki/Nils_J%C3%B6nsson_(Oxenstierna)",
    other: "",
  },

  {
    _id: "44",
    image: "/images/1448b.jpg",
    name: "Karl Knutsson",
    lifetime: "c. 1408 A.D. - 1470 A.D.",
    in_power: "1448 A.D. - 1457 A.D.",
    general: "Riksföreståndare.",
    rating: "Bonde",
    numReviews: "5",
    other: "Dog 15 maj 1470 på slottet Tre Kronor i Stockholm",
  },

  {
    _id: "46",
    image: "/images/jönsson.jpg",
    name: "Jöns Bengtsson (Oxenstierna)",
    lifetime: "1417 A.D. - 1467 A.D.",
    in_power: "1457 A.D. - 1457 A.D.",
    general:
      "Riksföreståndare och Ärkebiskop. Son till Bengt Jönsson (Oxenstierna) och Kristina Kristiernsdotter (Vasa). Samregerade med Erik Axelsson",
    rating: "Oxenstierna",
    numReviews: "5",
    other: "Dog 15 december 1467 på Öland",
  },

  {
    _id: "48",
    image: "/images/1457.jpg",
    name: "Kristian I",
    lifetime: "1426 A.D. -  1481 A.D.",
    in_power: "1457 A.D.-  1464 A.D.",
    general:
      "Son till greve Didrik av Oldenburg och Hedvig av Holstein. Avsatt 1464 och flydde till Danmark",
    rating: "Oldenburg",
    numReviews: "5",
    other: "Dog i Köpenhamn",
  },

  {
    _id: "49",
    image: "/images/1448b.jpg",
    name: "Karl Knutsson",
    lifetime: " 1408 A.D. -  1470 A.D.",
    in_power: "aug, 1464 A.D. - jan, 1465 A.D.",
    general: "Avsatt 1465",
    rating: "Bonde",
    numReviews: "5",
    other: "Dog 15 maj 1470 på slottet Tre Kronor i Stockholm",
  },

  {
    _id: "50",
    image: "/images/kettil.png",
    name: "Kettil Karlsson",
    lifetime: "1433 A.D.- 1465 A.D.",
    in_power: "1464 A.D. - 1465 A.D.",
    general:
      "Son till Karl Kristiernsson (Vasa) och Ebba Eriksdotter Krummedige. Riksföreståndare dec 1464",
    rating: "Vasa",
    numReviews: "5",
    other: "Dog 11 augusti 1465 en pestdöd på slottet Tre Kronor i Stockholm",
  },

  {
    _id: "51",
    image: "/images/jönsson.jpg",
    name: "Jöns Bengtsson (Oxenstierna)",
    lifetime: "1417 A.D. - 1467 A.D.",
    in_power: "1465 A.D. - 1466 A.D.",
    general:
      "Riksföreståndare och ärkebiskop. Son till Bengt Jönsson (Oxenstierna) och Kristina Kristiernsdotter (Vasa). Avsattes 1466.",
    rating: "Oxenstierna",
    numReviews: "5",
    other: "Dog 15 december 1467 på Öland",
  },

  {
    _id: "52",
    image: "/images/tott.png",
    name: "Erik Axelsson (Tott)",
    lifetime: "1415 A.D. - 1481 A.D.",
    in_power: "1466 A.D. - 1467 A.D.",
    general: "",
    rating: "Tott",
    numReviews: "5",
    other: "Dog i Februari eller mars 1481 i Viborg i Finland",
  },

  {
    _id: "53",
    image: "/images/1448b.jpg",
    name: "Karl Knutsson ",
    lifetime: " 1408 A.D. -  1470 A.D.",
    in_power: "1467 A.D. - 1470 A.D.",
    general: "",
    rating: "Bonde",
    numReviews: "5",
    other: "Dog 15 maj 1470 på slottet Tre Kronor i Stockholm",
  },
  {
    _id: "54",
    image: "/images/1470.jpg",
    name: "Sten Sture den äldre",
    lifetime: "1440 A.D. -  1503 A.D.",
    in_power: "1470 A.D.-  6 okt. 1497 A.D.",
    general:
      "Son till Gustav Anundsson Sture och Birgitta Stensdotter (Bielke)",
    rating: "nattochdag",
    numReviews: "5",
    other: "Död 14 december 1503 i Jönköping",
  },
  {
    _id: "55",
    image: "/images/1497.jpg",
    name: "Johan II",
    lifetime: " 1455 A.D. -  1513 A.D.",
    in_power: "okt, 1497 A.D. - aug, 1501 A.D.",
    general:
      "Son till Kristian I och Dorotea av Brandenburg. Johan II blev avsatt 1501.",
    rating: "Oldenburg",
    numReviews: "5",
    other: "Död 1513 i danska Ålborg",
  },
  {
    _id: "56",
    image: "/images/1470.jpg",
    name: "Sten Sture den äldre",
    lifetime: "1440 A.D. -  1503 A.D.",
    in_power: "nov, 1501 A.D.-  dec, 1503 A.D.",
    general:
      "Son till Gustav Anundsson Sture och Birgitta Stensdotter (Bielke)",
    rating: "nattochdag",
    numReviews: "5",
    other: "Död 14 december 1503 i Jönköping",
  },

  {
    _id: "57",
    image: "/images/1504.jpg",
    name: "Svante Nilsson",
    lifetime: "1460 A.D. - dec, 1511 A.D.",
    in_power: "1504 A.D. - 1511 A.D.",
    general:
      "Riksföreståndare. Son till Nils Sture (Natt och Dag) och Birgitta Karlsdotter (Bonde)",
    rating: "Natt och Dag",
    numReviews: "5",
    other: "Dog 1511 i Västerås",
  },

  {
    _id: "58",
    image: "/images/trolle.png",
    name: "Erik Trolle ",
    lifetime: "1460 A.D. - c. 1530 A.D.",
    in_power: "c. jun, 1512 A.D. - jul, 1512 A.D.",
    general:
      "Riksföreståndare. Son till Arvid Birgersson (Trolle) och Kristina Jonsdotter (Gädda) eller Beata Ivarsdotter (Tott).Avsatt den 23.e juli 1512 vid Sten Sture den yngres riksföreståndarval",
    rating: "trolle",
    numReviews: "5",
    other: "",
  },

  {
    _id: "59",
    image: "/images/1512.jpg",
    name: "Sten Sture den yngre",
    lifetime: "c. 1492 A.D. - 1050 A.D.",
    in_power: "jul, 1512 A.D. - 3 feb, 1520 A.D.",
    general:
      "Son till Svante Nilsson (Sture) och Iliana Erengisledotter (Gädda)",
    rating: "nattochdag",
    numReviews: "5",
    other: "",
  },
  {
    _id: "60",
    image: "/images/1513.jpg",
    name: "Kristian II",
    lifetime: "1481 A.D. -  25 jan, 1559 A.D.",
    in_power: "1 nov, 1520 A.D.-  1523 A.D.",
    general: "Son till kung Hans och Kristina av Sachsen",
    rating: "Oldenburg",
    numReviews: "5",
    other: "Dog 25 januari 1559 i fängelse på Kalundborg på Själland",
  },
  {
    _id: "61",
    image: "/images/1521.jpg",
    name: "Gustav Vasa",
    lifetime: " 12 maj, 1496 A.D. -  1560 A.D.",
    in_power: "6 jun, 1521 A.D.- 1560 A.D.",
    general:
      "Riksföreståndare från 1521 och Kung från 1523. Son till Erik Johansson (Vasa) och Cecilia Månsdotter (Eka)",
    rating: "Vasa",
    numReviews: "5",
    other: "Dog 29 september 1560 på slottet Tre Kronor i Stockholm",
  },
  {
    _id: "62",
    image: "/images/1560.jpg",
    name: "Erik (XIV) Vasa ",
    lifetime: "13 dec, 1533 A.D. - 26 jan, 1577 A.D.",
    in_power: "sep, 1560 A.D. - sep,1568 A.D.",
    general:
      "Son till Gustav Vasa och Katarina av Sachsen-Lauenburg. 29.e september 1568 tillfångatagen och avsatt av sina bröder Johan och Karl. Avsatt formellt 26 januari 1569 av ständerna",
    rating: "Vasa",
    numReviews: "5",
    other: "Dog 26 februari 1577 på Örbyhus slott i Uppland",
  },
  {
    _id: "63",
    image: "/images/1569.jpg",
    name: "Johan III",
    lifetime: "20 dec, 1537 A.D. - 17 nov, 1592 A.D.",
    in_power: "26 jan, 1569 A.D. - 1592 A.D.",
    general:
      "Son till Gustav Vasa och Margareta Eriksdotter (Leijonhufvud).Utropad till kung 30 september 1568 efter brodern Eriks avsättning",
    rating: "Vasa",
    numReviews: "5",
    other: "Dog 17 november 1592 på slottet Tre Kronor i Stockholm",
  },

  {
    _id: "64",
    image: "/images/1592.jpg",
    name: "Sigismund",
    lifetime: "20 jun, 1566 A.D. - 1632 A.D.",
    in_power: "nov, 1592 A.D. - apr, 1599 A.D.",
    general: "Son till Johan III och Katarina Jagellonic.",
    rating: "Vasa",
    numReviews: "5",
    other: "Dog 19 april 1632 i Warszawa",
  },

  {
    _id: "65",
    image: "/images/1599.jpg",
    name: "Karl IX",
    lifetime: "4 okt, 1550 A.D. - 30 okt, 1611 A.D.",
    in_power: "jul, 1599 A.D. - mar, 1611 A.D",
    general:
      "Riksföreståndare till 1604 sedan Kung. Son till Gustav Vasa och Margareta Eriksdotter (Leijonhufvud).Bekräftad av ständerna som riksföreståndare 24 juli 1599 vid Sigismunds avsättning",
    rating: "Vasa",
    numReviews: "5",
    other: "",
  },
  {
    _id: "66",
    image: "/images/1611.jpg",
    name: "Gustav II Adolf",
    lifetime: "9 dec, 1594 A.D. -  6 nov, 1632 A.D.",
    in_power: "30 okt, 1611 A.D. -  6 nov, 1632 A.D.",
    general:
      " Son till Karl IX och Kristina av Holstein-Gottorp. Även känd under namnet Gustav Adolf den store. Var omyndig vid faderns död, men myndighetsförklarades samma år",
    rating: "Vasa",
    numReviews: "5",
    other: "Stupade den 6.e november 1632 i slaget vid Lützen",
  },
  {
    _id: "67",
    image: "/images/1632.jpg",
    name: "Kristina",
    lifetime: " 7 dec, 1626 A.D. -  1741 A.D. ? ",
    in_power: "okt, 1632 A.D. - jun, 1654 A.D.",
    general:
      "Dotter till Gustav II Adolf och Maria Eleonora av Brandenburg. Kristina abdikerade till förmån för Karl X Gustav",
    rating: "Vasa",
    numReviews: "5",
    other: "Dog 1689 i Rom",
  },
  {
    _id: "68",
    image: "/images/1654.jpg",
    name: "Karl X Gustav",
    lifetime: "8 nov 1622 A.D.- 13 feb, 1660 A.D.",
    in_power: "jun, 1654 A.D. - apr, 1660 A.D.",
    general:
      "Son till greve Johan Kasimir av Pfalz-Zweibrücken och Karl IX:s dotter Katarina",
    rating: "Pfalziska",
    numReviews:
      "https://commons.wikimedia.org/wiki/File:King_Charles_X_Gustavus_(S%C3%A9bastien_Bourdon)_-_Nationalmuseum_-_19702.tif",
    other: "Dog i lunginflammation i Kungshuset i Göteborg",
  },

  {
    _id: "69",
    image: "/images/1660.png",
    name: "Karl XI Gustav",
    lifetime: "24 nov, 1655 A.D. - 5 apr, 1697 A.D.",
    in_power: "feb, 1660 A.D. - apr, 1697 A.D.",
    general: "Son till Karl X Gustav och Hedvig Eleonora av Holstein-Gottorp",
    rating: "Pfalziska",
    numReviews: "5",
    other: "Dog 5 april 1697 i magc.ncer på slottet Tre Kronor i Stockholm,",
  },

  {
    _id: "70",
    image: "/images/1697.jpg",
    name: "Karl XII",
    lifetime: "17 jun, 1682 A.D. - 30 nov, 1718 A.D.",
    in_power: "5 apr, 1697 A.D. - 30 nov 1718 A.D.",
    general: "Son till Karl XI och Ulrika Eleonora av Danmark",
    rating: "Pfalziska",
    numReviews: "5",
    other:
      "Dödsskjuten den 30.e november 1718 vid belägringen av Fredrikstens fästning i Norge",
  },

  {
    _id: "71",
    image: "/images/1719.png",
    name: "Ulrika Eleonora",
    lifetime: "23 jan, 1688 A.D. - 24 nov 1741 A.D.",
    in_power: "23 jan, 1719 A.D. - 29 feb, 1720 A.D.",
    general:
      "Dotter till Karl XI och Ulrika Eleonora av Danmark. Utropad till drottning 5 december 1718 vid budet om Karl XII:s död och vald av riksdagen den 23.e jan, 1719. Abdikerade till förmån för Fredrik I den 29.e feb 1720",
    rating: "Pfalziska",
    numReviews: "5",
    other: "24 november 1741 i Kungshuset i Stockholm",
  },
  {
    _id: "72",
    image: "/images/1720.jpg",
    name: "Fredrik I",
    lifetime: " 18 apr, 1676 A.D. -  25 mar, 1751 A.D.",
    in_power: "24 mar, 1720 A.D. - 25 mar 1751 A.D.",
    general:
      "Son till lantgreve Karl I av Hessen-Kassel och Maria Amalia av Kurland",
    rating: "Hessiska",
    numReviews: "5",
    other: "Död 25 mars 1751 av kallbrand i Kungshuset i Stockholm",
  },
  {
    _id: "73",
    image: "/images/1751.png",
    name: "Adolf Fredrik",
    lifetime: " 4 maj, 1710 A.D. -  12 feb, 1771 A.D.",
    in_power: "25 mar, 1751 A.D. - 12 feb 1771 A.D.",
    general:
      "Son till Kristian August av Holstein-Gottorp och Albertina Fredrika av Baden-Durlach",
    rating: "Holstein-Gottorp",
    numReviews: "5",
    other: "Dog av slaganfall på Stockholms slott",
  },
  {
    _id: "74",
    image: "/images/gustavIII.png",
    name: "Gustav III",
    lifetime: "13 jan, 1746 A.D.- 29 mar 1792 A.D.",
    in_power: "12 feb, 1771 A.D. - 29 mar, 1792 A.D.",
    general: "Son till Adolf Fredrik och Lovisa Ulrika av Preussen",
    rating: "Holstein-Gottorp",
    numReviews: "5",
    other:
      "Dog den 29.e mars 1792 av blodförgiftning och lunginflammation på Stockholms slott. Detta till följd av att han under en maskeradbal två veckor tidigare hade skjutits med ett skott i ryggen av Jacob Johan Anckarström.",
  },

  {
    _id: "75",
    image: "/images/1792.jpg",
    name: "Gustav IV Adolf",
    lifetime: "1 nov, 1778 A.D. - 7 feb, 1837 A.D.",
    in_power: "29 mar, 1792 A.D. - 29 mar, 1809 A.D.",
    general:
      "Son till Gustav III och Sofia Magdalena av Danmark. Gustav IV blev avsatt den 10 maj 1809 och landsförvisad.",
    rating: "Holstein-Gottorpska",
    numReviews: "5",
    other:
      "Dog 7.e februari 1837 på värdshuset Vita hästen i schweiziska Sankt Gallen",
  },

  {
    _id: "76",
    image: "/images/karlXIII.jpg",
    name: "Karl XIII",
    lifetime: "2 sep, 1748 A.D. - 5 feb, 1818 A.D.",
    in_power: "1809 A.D. -  1818 A.D.",
    general:
      "Riksföreståndare under 1809. Son till Adolf Fredrik och Lovisa Ulrika av Preussen",
    rating: "Holstein-Gottorpska",
    numReviews: "5",
    other: "Dog 5.e februari 1818 på Stockholms slott",
  },

  {
    _id: "77",
    image: "/images/1818.png",
    name: "Karl XIV",
    lifetime: "26 jan, 1763 A.D. - 8 mar 1844 A.D.",
    in_power: " 5 feb, 1818 A.D. - 8 mar 1844 A.D.",
    general:
      "Son till advokaten Henri Bernadotte och Jeanne de Saint-Jean. Innan 1810 hette han Jean Baptiste Jules Bernadotte",
    rating: "Bernadotte",
    numReviews: "5",
    other: "Dog av kallbrand på Stockholms Slott 8 mar, 1844",
  },
  {
    _id: "78",
    image: "/images/1844.jpg",
    name: "Oscar I",
    lifetime: "4 jul, 1799 A.D. -  8 jul, 1859 A.D.",
    in_power: "8 mar, 1844 A.D. - 8 jul, 1859 A.D.",
    general:
      "Son till Jean Baptiste Bernadotte och Desirée Clary. Födselname: Joseph François Osc.r Bernadotte",
    rating: "Bernadotte",
    numReviews: "5",
    other: "Dog av hjärntumör på Stockholm Slott",
  },
  {
    _id: "79",
    image: "/images/1859.jpg",
    name: "Karl XV",
    lifetime: " 3 maj, 1826 A.D. -  18 sep, 1872 A.D..",
    in_power: " 1857 A.D. - 18 sep, 1872 A.D.",
    general:
      "Regent till 1559 därefter Kung. Son till Oscar I och Josefina av Leuchtenberg",
    rating: "Bernadotte",
    numReviews: "5",
    other: "",
  },
  {
    _id: "80",
    image: "/images/1872.jpg",
    name: "Oscar II",
    lifetime: "21 jan, 1829 A.D. - 8 dec, 1907 A.D.",
    in_power: "18 sep, 1872 A.D. - 8 dec, 1907 A.D.",
    general: "Son till Oscar I och Josefina av Leuchtenberg",
    rating: "Bernadotte",
    numReviews: "5",
    other: "Död 8 december 1907 på Stockholms slott",
  },

  {
    _id: "81",
    image: "/images/1907.jpg",
    name: "Gustav V",
    lifetime: "16 jun, 1858 A.D. - 1950 A.D.",
    in_power: "8 dec, 1907 A.D. - 29 okt, 1950 A.D.",
    general: "Son till Osc.r II och Sofia av Nassau",
    rating: "Bernadotte ",
    numReviews: "5",
    other: "Dog 29.e oktober 1950 på Drottningholms slott",
  },

  {
    _id: "82",
    image: "/images/1950.jpg",
    name: "Gustav VI Adolf",
    lifetime: "11 nov, 1882 A.D. - 15 sep, 1973 A.D.",
    in_power: "29 okt, 1950 A.D. - 15 sep, 1973 A.D.",
    general: "Son till Gustaf V och Victoria av Baden",
    rating: "Bernadotte ",
    numReviews: "5",
    other: "",
  },

  {
    _id: "83",
    image: "/images/1973.jpg",
    name: "Carl XVI Gustav",
    lifetime: "30 apr, 1946 A.D. - Today",
    in_power: "15 sep, 1973 A.D. - Today",
    general:
      "Son till Gustaf VI Adolfs son arvprins Gustaf Adolf och prinsessan Sibylla",
    rating: "Bernadotte",
    numReviews: "5",
    other: "",
  },
];
export default queen;
